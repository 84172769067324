import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const API_TOKEN = `bXktc2VjcmV0LXRva2VuLWZvci13ZWRkaW5nLWludml0YXRpb24=`;
const BASE_URL = `https://us-central1-wedding-invitation-12381.cloudfunctions.net/`
// const AXIOS_CONFIG_FAILED = {
//   headers: {
//     Authorization: `Bearer ${apitoken}+1`,
//     "Access-Control-Allow-Origin": "*",
//     "Content-Type": "application/json",
//   },
// };


export const getAPIClient = async (): Promise<AxiosInstance> => {

  const options: AxiosRequestConfig = {
    baseURL: BASE_URL,
    validateStatus: (status: number) => {
      return status >= 200 && status < 300;
    },
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const client = axios.create(options);

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return client;
};
