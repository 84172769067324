import classes from "./InvitationHeader.module.scss";
import SvgRingsIcon from "../../components/icons/RingsIcon";
import SvgFlightIcon from "../../components/icons/FlightIcon";
import SvgLocationIcon from "../../components/icons/LocationIcon";
import { WeddingLocation } from "./AgreementView";
import { useParams } from "react-router-dom";

type Props = {
  weddingDate: String;
  weddingLocationChurch: WeddingLocation;
  weddingLocationParty: WeddingLocation;
};

type InformationContainerProps = {
  icon: React.ReactNode;
  content: string[];
};
const InformationContainer = ({ icon, content }: InformationContainerProps) => {
  return (
    <div className={classes.informationContainer}>
      {icon}
      <div className={classes.contentWrapper}>
        {content.map((i, index) => (
          <span key={index}>{i}</span>
        ))}
      </div>
    </div>
  );
};

export const InvitationHeader = ({
  weddingDate,
  weddingLocationChurch,
  weddingLocationParty,
}: Props) => {
  const { id: invitationId } = useParams();

  const informationLocationChurch = {
    icon: <SvgLocationIcon />,
    content: [
      weddingLocationChurch.street,
      `${weddingLocationChurch.plz} ${weddingLocationChurch.city}`,
    ],
  };
  const informationLocationParty = {
    icon: <SvgLocationIcon />,
    content: [
      `${weddingLocationParty.street}`,
      `${weddingLocationParty.plz} ${weddingLocationParty.city}`,
    ],
  };

  const informationWeddingDate = {
    icon: <SvgFlightIcon />,
    content: [`${weddingDate}`, `#LOVE-${invitationId}`],
  };

  const title = (
    <div className={classes.title}>
      <div className={classes.information}>
        <h1>WIR SAGEN JA</h1>
      </div>
    </div>
  );

  return (
    <>
      {title}
      <div className={classes.sublineInformation}>
        <div className={classes.sublineItem}>
          <div className={classes.subtitle}>
            <h1>Alexander</h1>
            <SvgRingsIcon />
            <h1>Simone</h1>
          </div>
        </div>
        <div className={classes.sublineContentWrapper}>
          <div className={classes.sublineItem}>
            <InformationContainer
              icon={informationWeddingDate.icon}
              content={informationWeddingDate.content}
            />
          </div>
          <div className={classes.sublineItem}>
            <InformationContainer
              icon={informationLocationChurch.icon}
              content={informationLocationChurch.content}
            />
            <InformationContainer
              icon={informationLocationParty.icon}
              content={informationLocationParty.content}
            />
          </div>
        </div>
      </div>
    </>
  );
};
