import axios from "axios";
import { getAPIClient } from "./client";

const apitoken = `bXktc2VjcmV0LXRva2VuLWZvci13ZWRkaW5nLWludml0YXRpb24=`;
const AXIOS_CONFIG = {
  headers: {
    Authorization: `Bearer ${apitoken}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export type InvitationData = {
  guests: string[];
  invite: InvitationResponse,
  createdAt: number;
  updatedAt: number;
  updated: number;
};


export type InvitationResponse = {
  status: InvitationStatus | undefined;
  numberOfAcceptances: { person: number; children: number };
  comment: string;
};

export type InvitationAPI = {
  id: string;
  invite: InvitationResponse;
};

export type InvitationStatus = "ACCEPTED" | "REJECTED";

const mapInvitationDataToInvitationApi = (
  id: string,
  invite: InvitationData
): InvitationAPI => {

  return {
    id: id,
    invite: invite.invite
  };
};

export type Passagier = {
  name: string;
};


const mappedReponseToInvitation = (ref: InvitationData, response: InvitationResponse): InvitationData => {
  return {
    ...ref,
    invite: response
  }
}

export const InvitationApi = {
  async getInvitation(id: string): Promise<InvitationData> {
    const response = await axios.get<InvitationData>(
      `https://us-central1-wedding-invitation-12381.cloudfunctions.net/guest?id=${id}`,
      AXIOS_CONFIG
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error(`Can't fetch Invitation with id ${id}`);
  },

  async update(id: string, invite: InvitationData): Promise<InvitationData | null> {
    const data = mapInvitationDataToInvitationApi(id, invite);
    try {
      const response = await getAPIClient().then((client) =>
        client.post<InvitationResponse>(`/invitation`, data)
      );
      return mappedReponseToInvitation(invite, response.data);
    } catch (error) {
      return null;
    }
  },

  async subscribe(id: string, mail: string): Promise<boolean> {
    try {
      console.log(id, mail);
      const response = await getAPIClient().then((client) =>
        client.post<InvitationResponse>(`/subscribe`, { id: id, mail: mail })
      );
      if (response.status === 204) {
        return true;
      }
      return false
    } catch (error) {
      throw Error(`Can't add subscription with id ${id}`);
    }
  },
};
