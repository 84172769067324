import React, { useState } from "react";
import { InvitationApi, InvitationStatus } from "../../api/invitation.api";
import { Button, Form } from "react-bootstrap";
import classes from "./FinishedView.module.scss";
import Image from "react-bootstrap/Image";
import SadImg from "../../images/sad.png";
import HappyImg from "../../images/konfetti.png";

type Props = {
  invitationId: string;
  status: InvitationStatus;
  edit: () => void;
};


export const FinishedView = ({ status, edit, invitationId }: Props) => {

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubscribe = async (e: any) => {
    e.preventDefault();

    if (invitationId && email) {
      setIsSubscribed(await InvitationApi.subscribe(invitationId, email));
    }
  }

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  if (status === "ACCEPTED") {
    return (
      <div className={classes.centerScreen}>
        <div className={classes.contentContainer}>
          <Image src={HappyImg}></Image>
          <p>
            Geschafft - <strong>Du hast zugesagt!</strong> Wir könnes es kaum erwarten mit dir die Korken knallen zu lassen.
          </p>
          <Button variant="dark" onClick={edit}>
            Ändern
          </Button>
        </div>
        <div className={classes.contentContainer}>
          <h2>Weitere Informationen:</h2>
          Du findest Informationen rund um die Hochzeit auf folgender Webseite.
          <a href="https://hochzeit-herholz.de">https://hochzeit-herholz.de</a>
        </div>
        <div className={classes.contentContainer}>
          {!isSubscribed ? <>
            <h2>Newsletter:</h2>
            <p>Wir würden dich außerdem bitten, deine E-Mail Adresse zu hinterlegen. Warum fragst du dich? Ganz einfach, um euch schneller kontaktieren zu können und euch über updates zu informieren.</p>
            <Form onSubmit={handleSubscribe}>
              <Form.Control onChange={handleEmailChange} type="email" placeholder="deine-email@adresse.de" />
              <Form.Text className="text-muted">
                Wir behandeln deine E-Mail Adresse vertraulich.
              </Form.Text>
              <div>
                <Button variant="dark" type="submit">
                  Bestätigen
                </Button>
              </div>
            </Form>
          </>
            : <div>
              Vielen Dank du wirst von uns hören.
            </div>}

        </div>
      </div>
    );
  }

  if (status === "REJECTED") {
    return (
      <div className={classes.centerScreen}>
        <div className={classes.contentContainer}>
          <Image src={SadImg}></Image>
          <p>
            Oh nein - <strong>Du hast abgesagt!</strong> Schade, wir hätten
            gerne mit dir gemeinsam gefeiert.
          </p>

          <Button variant="dark" onClick={edit}>
            Ändern
          </Button>
        </div>
      </div>
    );
  }

  return <div>ERROR</div>;
};
