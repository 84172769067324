import React from "react";
import classes from "./NotFoundView.module.scss";

export const NotFoundView = () => {
  return (
    <div className={classes.centerScreen}>
      <h1 id="errorText">Oops! Da ist etwas schief gelaufen!</h1>
      <span>
        Versuche es erneut oder melde dich auf einem anderen Weg bei uns.
      </span>
    </div>
  );
};
