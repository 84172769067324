import React from "react";
import { Spinner } from "react-bootstrap";
import classes from "./LoadingView.module.scss";

export const LoadingView = () => {
  return (
    <div className={classes.centerScreen}>
      <Spinner animation="grow" />
    </div>
  );
};
