import {
  InvitationApi,
  InvitationData,
  InvitationStatus,
} from "../../api/invitation.api";
import classes from "./InvitationForm.module.scss";
import { useEffect, useState } from "react";
import { Button, Form, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { ErrorLabel } from "../error-label/ErrorLabel";


type Props = {
  invitation: InvitationData;
  invitationChanged: (invitation: InvitationData) => void;
  invitationId: string;
  handleFinished: (status: boolean) => void;
  handleError: (status: string) => void;
};

const LoadingState = () =>
  <div className={classes.loadingWrapper}>
    <Spinner animation="grow" />
  </div>


export const InvitationForm = ({
  invitation,
  invitationId,
  invitationChanged,
  handleFinished,
  handleError
}: Props) => {

  const [amountAdults, setAmountAdults] = useState(0);
  const [amountChildren, setAmountChildren] = useState(0);
  const [passagiers, setPassagiers] = useState<string[]>();
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState<InvitationStatus>();
  const [validated, setValidated] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (invitation) {
      setPassagiers(invitation.guests);
    }
  }, [invitation]);

  useEffect(() => {
    setValidated(status === "ACCEPTED" && amountAdults === 0 ? false : true);
  }, [amountAdults, validated, status]);

  useEffect(() => {
    invitationChanged({
      ...invitation,
      invite: {
        numberOfAcceptances: {
          person: amountAdults,
          children: amountChildren,
        },
        comment,
        status,
      },
    });
  }, [amountAdults, amountChildren, comment, status]);

  const handlePersonChange = (e: any) => {
    const { value } = e.target;
    setAmountAdults(value);
  };

  const handleChildrenChange = (e: any) => {
    const { value } = e.target;
    setAmountChildren(value);
  };

  const handleCommentChange = (e: any) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validated) {
      setShowValidation(true);
      return;
    }

    if (status === "REJECTED") {
      setAmountAdults(0);
      setAmountChildren(0);
    }

    if (invitationId && invitation) {
      try {
        setIsLoading(true);
        const updatedInvitation = await InvitationApi.update(invitationId, {
          ...invitation,
          invite: {
            numberOfAcceptances: {
              person: amountAdults,
              children: amountChildren,
            },
            comment,
            status,
          },
        });
        if (updatedInvitation) {
          invitationChanged(updatedInvitation);
          setIsLoading(false);
          handleFinished(true);
        }
        else {
          handleError("Can't update!")
          handleFinished(false);
        }
      }
      catch (e) {
        handleError("Can't update!")
      }
    }
  };

  if (!passagiers) {
    return (
      <div>
        Da ist etwas schief gelaufen. Versuche es erneut oder kontaktiere uns
        persönlich.
      </div>
    );
  }


  if (isLoading) {
    return <LoadingState />
  } else {
    return (
      <>
        <div className={classes.passagier}>
          <h4>Passagier:</h4>
          <div className={classes.passagierNames}>
            {passagiers.map((i, index) => (
              <span key={index}>{i}</span>
            ))}
          </div>
        </div>
        <Form className={classes.submitForm} onSubmit={handleSubmit}>
          <Form.Select onChange={handlePersonChange} aria-label="Select Person">
            <option>Anzahl Erwachsene</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="99">mehr als 5</option>
          </Form.Select>
          <Form.Select
            onChange={handleChildrenChange}
            aria-label="Select Children"
          >
            <option>Anzahl Kinder</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Form.Select>
          <h4>Bemerkung:</h4>
          <InputGroup>
            <FormControl
              maxLength={500}
              rows={2}
              value={comment}
              onChange={handleCommentChange}
              as="textarea"
              aria-label="With textarea"
            />
          </InputGroup>
          <div className={classes.formButtonWraper}>
            {!validated && showValidation ? (
              <ErrorLabel msg="Wähle zu nächst die Anzahl der Erwachsene aus." />
            ) : undefined}

            <div className={classes.formButtons}>
              <Button
                onClick={() => setStatus("ACCEPTED")}
                variant="dark"
                type="submit"
              >
                Zusagen
              </Button>
              <Button
                onClick={() => setStatus("REJECTED")}
                variant="danger"
                type="submit"
              >
                Absagen
              </Button>
            </div>
          </div>
        </Form>
      </>
    );
  }
};

