import React from "react";
import classes from "./ErrorLabel.module.scss";

type props = {
  msg: string;
};

export const ErrorLabel = ({ msg }: props) => {
  return (
    <div className={classes.wrapper}>
      <span>{msg}</span>
    </div>
  );
};
