import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { AgreementView } from "./pages/agreement-view/AgreementView";
import { NotFoundView } from "./pages/notfound-view/NotFoundView";

export const App = () => {
  return (
    <Routes>
      <Route path="/invite/:id" element={<AgreementView />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};
