import { format } from "date-fns";
import { useEffect, useState } from "react";
import classes from "./AgreementView.module.scss";
import { InvitationApi, InvitationData } from "../../api/invitation.api";
import { InvitationHeader } from "./InvitationHeader";
import { AgreementInformation } from "./AgreementInformation";
import { useParams } from "react-router-dom";
import { LoadingView } from "../loading-view/LoadingView";
import { NotFoundView } from "../notfound-view/NotFoundView";
import { FinishedView } from "../finished-view/FinishedView";
import { InvitationForm } from "../../components/invitation-form/InvitationForm";
import { Button } from "react-bootstrap";

export type WeddingLocation = {
  label: string;
  street: string;
  plz: string;
  city: string;
};

export const AgreementView = () => {
  const weddingDate = format(
    new Date(2022, 8, 3, 14, 0),
    "dd.MM.yyyy '-' HH:mm 'Uhr'"
  );

  const [invitation, setInvitation] = useState<InvitationData>();
  const { id: invitationId } = useParams();

  const [state, setState] = useState<{
    status: string;
    invitation?: InvitationData | undefined;
    error?: string | undefined;
  }>({ status: "IDLE" });

  useEffect(() => {
    if (invitationId) {
      setState({ status: "LOADING" });
      InvitationApi.getInvitation(invitationId).then(
        (invite: InvitationData) => {
          setInvitation(invite);
          setState({ status: "FETCHED", invitation: invite });
        },
        (error) => setState({ status: "ERROR", error })
      ).catch(error => {
        console.log(error)
      });
    }
  }, [invitationId]);

  const weddingLocationParty: WeddingLocation = {
    label: "Villa Blanca",
    street: "Villenstraße 2",
    plz: "47229",
    city: "Duisburg",
  };

  const weddingLocationChurch: WeddingLocation = {
    label: "St. Ludgeruskirche",
    street: "Birkenstraße 73",
    plz: "46242",
    city: "Bottrop",
  };

  const submitDate = format(new Date(2022, 5, 1), "dd.MM.yyyy");
  const handleInvitationChanged = (invitation: InvitationData) => {
    setInvitation(invitation);
  };

  const handleFinished = (finished: boolean) => {
    if (finished) {
      setState({ status: "FINISHED" });
    }
  };

  const handleError = (error: string) => {
    setState({ status: "ERROR", error });
  }

  const handleEdit = () => {
    setState({ status: "FETCHED" });
  };

  if (state.status === "LOADING" || state.status === "IDLE") {
    return <LoadingView />;
  }

  if (state.status === "FINISHED") {
    if (invitation && invitation.invite.status && invitationId) {
      return (
        <FinishedView invitationId={invitationId} edit={handleEdit} status={invitation.invite.status} />
      );
    }
  }

  if (state.status === "FETCHED") {
    if (invitation && invitationId) {
      return (
        <div className={classes.container}>
          <InvitationHeader
            weddingDate={weddingDate}
            weddingLocationChurch={weddingLocationChurch}
            weddingLocationParty={weddingLocationParty}
          />

          <div className={classes.agreement}>
            <AgreementInformation
              submitDate={submitDate}
              weddingDate={weddingDate}
              weddingLocationChurch={weddingLocationChurch}
              weddingLocationParty={weddingLocationParty}
            />

            <InvitationForm
              invitation={invitation}
              invitationId={invitationId}
              invitationChanged={handleInvitationChanged}
              handleFinished={handleFinished}
              handleError={handleError}
            />
          </div>
        </div>
      );
    }
  }

  if (state.status === "ERROR") {
    return <NotFoundView />;
  }

  return <LoadingView />;
};
