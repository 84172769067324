import { WeddingLocation } from "./AgreementView";
import classes from "./AgreementView.module.scss";

type Props = {
  weddingDate: string;
  submitDate: string;
  weddingLocationChurch: WeddingLocation;
  weddingLocationParty: WeddingLocation;
};

export const AgreementInformation = ({
  weddingLocationChurch,
  weddingLocationParty,
  weddingDate,
  submitDate,
}: Props) => {
  const paragraph = {
    title: "Attention please - We're ready for boarding!",
    content: [
      `Wir wollen dich/euch auf eine ganz besondere Reise mitnehmen. Denn  <strong> WIR SAGEN JA</strong> und würden uns freuen wenn du dabei bist!`,
      `Die kirchliche Trauung findet in der ${weddingLocationChurch.label} am <strong>${weddingDate}</strong> statt. Im Anschluss wollen wir in der <strong>${weddingLocationParty.label}</strong> gemeinsam mit euch feiern.`,
      `Bitte gebe uns bis zum <strong>${submitDate}</strong> eine Rückmeldung, ob du mit uns gemeinsam abhebst.`,
      `Liebe Grüße, Simone und Alexander`,
    ],
  };

  return (
    <div className={classes.agreementInfo}>
      <h3>{paragraph.title}</h3>
      {paragraph.content.map((p, index) => {
        return <p key={index} dangerouslySetInnerHTML={{ __html: p }} />;
      })}
    </div>
  );
};
