import * as React from "react";

const SvgFlightIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.781 21.246h22.485V24H.781v-2.754Zm23.16-12.888c-.249-1.102-1.23-1.762-2.178-1.46L15.48 8.854 7.314 0 5.03.702l4.899 9.873-5.882 1.831-2.331-2.12L0 10.823l3.065 6.182s8.426-2.616 19.61-6.1c.958-.316 1.514-1.445 1.266-2.547Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFlightIcon;
